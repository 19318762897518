import { Avatar, AvatarGroup } from '@mui/material';
import styled from 'styled-components';
import Body from 'components/common/body';
import { User } from 'graphql/generated';
import { theme } from 'utils/theme';

const StatsOnCompletionDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px 0;
`;

interface IdealistCompletionStatsProps {
  completedBy: Pick<User, 'id' | 'name' | 'image'>[];
  noOneCompletedText?: string;
}

const DEFAULT_NO_ONE_COMPLETED = 'Nobody completed this list yet. Be the first!';

const IdealistCompletionStats = ({
  completedBy,
  noOneCompletedText = DEFAULT_NO_ONE_COMPLETED,
}: IdealistCompletionStatsProps) => (
  <StatsOnCompletionDiv>
    <Body size="small" color={theme.colors.gray600} margin="0 10px 0 0">
      {completedBy?.length
        ? `${completedBy.length} ${completedBy.length > 1 ? 'people' : 'person'} completed it`
        : noOneCompletedText}
    </Body>
    <AvatarGroup max={3}>
      {completedBy?.map(({ id: completedById, image: completedByImage, name }) => (
        <Avatar
          key={completedById}
          alt={name || 'Another user'}
          src={completedByImage || undefined}
        />
      ))}
    </AvatarGroup>
  </StatsOnCompletionDiv>
);

export default IdealistCompletionStats;
