import { GetServerSidePropsContext, NextComponentType, NextPageContext } from 'next';
import { getSession, useSession } from 'next-auth/react';
import { ApolloPageContext, WithApolloProps } from 'next-with-apollo';
import Router from 'next/router';
import { PROFILE_COMPLETION_URL, SIGNIN_URL } from 'constants/urls';
import { VerifyUser } from 'graphql/withAuthenticatedUser';

interface RedirectOptions {
  url?: string;
  nextUrl?: string;
}

export const serverSideAuthUserOrRedirect = async (
  context: GetServerSidePropsContext,
  options?: RedirectOptions,
) => {
  const session = await getSession(context);
  const { url, nextUrl } = options || {};

  if (!session?.user) {
    return {
      redirect: {
        destination: `${url || SIGNIN_URL}${nextUrl ? `?nextUrl=${nextUrl}` : ''}`,
      },
    };
  }

  return { props: { user: session.user } };
};

export const useAuthenticatedUser = (): VerifyUser | null => {
  const { data, status } = useSession();

  return status === 'authenticated' ? (data.user as VerifyUser) : null;
};

const redirectToSignIn = (res: PageContext['res'], nextUrl?: string) => {
  const signinUrl = `${SIGNIN_URL}${nextUrl ? `?nextUrl=${nextUrl}` : ''}`;

  if (typeof res !== 'undefined') {
    res.writeHead(302, { Location: signinUrl });
    return res.end();
  }

  return Router.push(signinUrl);
};

const redirectToProfileCompletion = (res: PageContext['res'], nextUrl?: string) => {
  const profileCompletionUrl = `${PROFILE_COMPLETION_URL}${nextUrl ? `?nextUrl=${nextUrl}` : ''}`;

  if (typeof res !== 'undefined') {
    res.writeHead(302, { Location: profileCompletionUrl });
    return res.end();
  }

  return Router.push(profileCompletionUrl);
};

type PageContext = Pick<
  NextPageContext & ApolloPageContext,
  'req' | 'res' | 'pathname' | 'apolloClient'
>;
type VerifyAuthGetInitialProps = NextComponentType<
  PageContext,
  { user: VerifyUser | null } | boolean | void,
  void
>['getInitialProps'];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type VerifyAuthMethodRequired = (ctx: PageContext, extraData: { user: VerifyUser }) => any;
type VerifyAuthMethodOptional = (
  ctx: PageContext,
  extraData: { user: VerifyUser | null },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any;

type VerifyAuthOptions = { required?: boolean; onProfileCompletionPage?: boolean };

type VerifyAuthRequired = (
  options: VerifyAuthOptions,
  method?: VerifyAuthMethodRequired,
) => VerifyAuthGetInitialProps;

type VerifyAuthOptional = (
  options: VerifyAuthOptions,
  method: VerifyAuthMethodOptional,
) => VerifyAuthGetInitialProps;

export const verifyAuth: VerifyAuthRequired & VerifyAuthOptional = (
  options: VerifyAuthOptions,
  method: VerifyAuthMethodRequired | VerifyAuthMethodOptional | undefined,
) => {
  const getInitialProps: VerifyAuthGetInitialProps = async (ctx) => {
    const { req, res, pathname } = ctx;
    const session = await getSession(ctx);

    if (!session?.user) {
      return redirectToSignIn(res, req?.url || pathname);
    }

    const user = session.user as VerifyUser;

    if (!user.username && !options.onProfileCompletionPage) {
      return redirectToProfileCompletion(res);
    }

    return method
      ? (method as VerifyAuthMethodOptional)(ctx, {
          user,
        })
      : { user };
  };

  return getInitialProps;
};

export type VerifyAuthPage = NextComponentType<
  NextPageContext & ApolloPageContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  WithApolloProps<unknown> & { user: VerifyUser }
>;
