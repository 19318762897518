import Link from 'components/common/link';
import { USER_PROFILE_URL } from 'constants/urls';
import { User } from 'graphql/generated';
import { theme } from 'utils/theme';

const UsernameLink = ({ username }: Pick<User, 'username'>) => (
  // || '' for ts, we know users should have usernames
  <Link inline color={theme.customColors.linkBlue} href={USER_PROFILE_URL(username || '')}>
    @{username}
  </Link>
);

export default UsernameLink;
