import gql from 'graphql-tag';

export const USER_CORE_FRAGMENT = gql`
  fragment UserCore on User {
    id
    username
    image
  }
`;

export const IDALIST_ITEM_CORE_FRAGMENT = gql`
  fragment IdealistItemCore on IdealistItem {
    id
    description
    link
    images {
      id
      src
    }
  }
`;

export const IDEALIST_LOCATION_CORE_FRAGMENT = gql`
  fragment IdealistLocationCore on IdealistLocation {
    id
    name
    lat
    long
  }
`;

export const IDEALIST_CORE_FRAGMENT = gql`
  fragment IdealistCore on Idealist {
    id
    createdAt
    title
    image
    description
    isDraft
  }
`;

export const FEED_IDEALIST_WITH_USER_STATS_FRAGMENT = gql`
  fragment FeedIdealistWithUserStats on IdealistWithUserStats {
    idealist {
      id
      createdAt
      title
      description
      image
      isDraft
      creator {
        ...UserCore
      }
      location {
        id
        name
      }
      completedBy {
        name
        ...UserCore
      }
    }
    likeCount
    likedByUser
    completedByUser
  }

  ${USER_CORE_FRAGMENT}
`;
