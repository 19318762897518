import { MapPin } from 'react-feather';
import styled from 'styled-components';
import Body from 'components/common/body';
import ProfileImage from 'components/idealist/profileImage';
import { PROFILE_PICTURE_FEED_SIZE } from 'constants/images';
import { Idealist, User } from 'graphql/generated';
import { getDateString } from 'utils/date';
import { theme } from 'utils/theme';
import UsernameLink from '../username/usernameLink';

const ProfileImageInfoDiv = styled.div`
  margin-top: 10px;
  display: flex;
  width: 100%;
  align-items: center;
`;

const UserInfoAndLocationDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 10px 7px;
`;

const LocationDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 4px 0 0 7px;
`;

interface IdealistDetailsProps {
  idealist: Pick<Idealist, 'location' | 'isDraft' | 'createdAt'> & {
    creator: Pick<User, 'username' | 'image'>;
  };
}

const IdealistDetails = ({ idealist }: IdealistDetailsProps) => (
  <ProfileImageInfoDiv>
    <ProfileImage
      src={idealist.creator.image}
      username={idealist.creator.username}
      height={PROFILE_PICTURE_FEED_SIZE}
      width={PROFILE_PICTURE_FEED_SIZE}
    />

    <UserInfoAndLocationDiv>
      <Body size="small" color={theme.colors.gray600} margin="0 0 0 10px">
        {idealist.isDraft && (
          <Body size="small" color={theme.colors.red500}>
            DRAFT&nbsp;
          </Body>
        )}
        Created by&nbsp;
        <UsernameLink {...idealist.creator} />
        &nbsp;on {getDateString(new Date(idealist.createdAt))}
      </Body>
      {idealist.location && (
        <LocationDiv>
          <MapPin size={16} color={theme.colors.gray500} />
          &nbsp;
          <Body size="xsmall" color={theme.colors.gray600}>
            {idealist.location?.name}
          </Body>
        </LocationDiv>
      )}
    </UserInfoAndLocationDiv>
  </ProfileImageInfoDiv>
);

export default IdealistDetails;
