import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';
import { theme } from 'utils/theme';

interface ProfileImageProps {
  height?: number;
  width?: number;
  boxShadowColor?: string;
  username?: string | null;
  src?: string | null;
}

const OuterProfileImageDiv = styled.div<
  Pick<ProfileImageProps, 'height' | 'width' | 'boxShadowColor'>
>`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  min-height: ${(props) => props.height}px;
  min-width: ${(props) => props.width}px;
  border-radius: 50%;
  ${(props) =>
    props.boxShadowColor
      ? `box-shadow: 0 0px ${0.1 * (props.height || 40)}px 0px ${props.boxShadowColor};`
      : ''}
  backdrop-filter: blur(5px);
  z-index: 50;
  background-color: ${theme.colors.gray300};

  display: inline-block;
`;

const makeImageStyle = (): React.CSSProperties => ({
  borderRadius: '50%',
  overflow: 'hidden',
});

const ProfileImage = ({ height = 40, width = 40, src, username, ...rest }: ProfileImageProps) => {
  // google image hack: https://stackoverflow.com/a/65601247
  const scaledUpImage = src?.replace('s96-c', `s${height * 10}-c`); // * 10 works well in practice

  return (
    <OuterProfileImageDiv height={height} width={width} {...rest}>
      {src && (
        <Image
          priority
          width={width}
          height={height}
          alt={username || ''}
          src={scaledUpImage || ''}
          style={makeImageStyle()}
          loading="eager"
        />
      )}
    </OuterProfileImageDiv>
  );
};

export default ProfileImage;
